/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import BreadcrumbProvider from 'components/elements/Misc/Breadcrumb'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'

const Section = styled.section``

const Container = styled.div``

const BannerWrapper = styled.div`
  @media (min-width: 992px) {
    min-height: 480px;
  }
`

const DescriptionWrapper = styled.div`
  position: relative;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.primary};
  padding-bottom: 1rem !important;

  &:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: ${({ theme }) => theme.color.primary};
  }

  @media (min-width: 992px) {
    min-height: 480px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    & strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
    @media (min-width: 992px) {
      font-size: 40px;
      line-height: 40px;
    }
  }
  & p {
    font-size: 18px;
    opacity: 0.8;
  }
`

const ImageWrapper = styled.div`
  @media (min-width: 992px) {
    height: 100%;
    position: absolute;
    right: 0;
    padding: 0 !important;
  }
`

const Col = styled.div`
  @media (min-width: 992px) {
    padding-right: 0 !important;
  }
`

const Image = styled(Plaatjie)`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`

const ButtonWrapper = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
  }
`

const BannerDefault: React.FC<BannerProps> = ({ fields, pageContext }) => (
  <Section className="mb-5 pb-lg-5">
    <BannerWrapper className="position-relative">
      <Container className="container h-100">
        <div className="row h-100">
          <ImageWrapper className="col-lg-6 col-xl-7">
            <Image image={fields.image} alt="" />
          </ImageWrapper>
          <Col className="col-lg-6 col-xl-5 order-lg-1">
            <DescriptionWrapper className="position-relative h-100">
              <div className="d-flex align-items-center">
                <BreadcrumbProvider
                  pageContext={pageContext}
                  className="text-white"
                />
              </div>
              <div className="d-flex flex-column justify-content-center">
                <Content
                  content={fields.description}
                  className="pe-lg-5 me-3"
                />
              </div>
              {(fields.links?.length || 0) > 0 && (
                <ButtonWrapper>
                  {fields.links?.[0] && (
                    <ButtonPrimary
                      to={fields.links?.[0]?.link?.url || '/'}
                      key={0}
                    >
                      {fields.links?.[0]?.link?.title}
                    </ButtonPrimary>
                  )}
                  {fields.links?.[1] && (
                    <ButtonSecondary
                      to={fields.links?.[1]?.link?.url || '/'}
                      key={1}
                    >
                      {fields.links?.[1]?.link?.title}
                    </ButtonSecondary>
                  )}
                </ButtonWrapper>
              )}
            </DescriptionWrapper>
          </Col>
        </div>
      </Container>
    </BannerWrapper>
  </Section>
)

export default BannerDefault
