import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import Chevron from 'img/chevronbanner.inline.svg'
import { motion } from 'framer-motion'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'
import NavLink from 'components/shared/NavLink'

const BannerWrapper = styled.div`
  @media (min-width: 1400px) {
    min-height: 907px;
  }
`

const BannerIframeWrapper = styled.div`
  padding-top: 56.5%;
`

const ContentWrapper = styled.div`
  z-index: 2;

  @media (min-width: 768px) {
    padding-top: 12.5%;
  }

  @media (min-width: 576px) {
    top: 100px;
  }

  @media (max-width: 575.98px) {
    top: 20px;
  }

  @media (max-width: 340px) {
    top: 10px;
  }
`

const Iframe = styled.iframe`
  width: 100vw !important;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  & * {
    width: 100vw !important;
  }
`

const Content = styled(ParseContent)`
  position: relative;
  z-index: 2;

  & h1 {
    @media (min-width: 992px) {
      font-size: 110px;
    }
    @media (max-width: 991px) {
      font-size: 90px;
    }
    @media (max-width: 575px) {
      font-size: 30px;
    }
  }

  & h2 {
    @media (min-width: 992px) {
      font-size: 50px;
    }
    @media (max-width: 991px) {
      font-size: 30px;
    }
    @media (max-width: 575px) {
      font-size: 18px;
    }
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);
    @media (min-width: 992px) {
      line-height: 80px;
    }
    @media (max-width: 991px) {
      line-height: 60px;
    }
    @media (max-width: 575px) {
      line-height: 30px;
    }
  }

  & p {
    @media (max-width: 575px) {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      & span {
        margin: 0 auto;
      }
    }
  }
`

const ChevronWrapper = styled(motion.div)`
  z-index: 3;
  left: calc(50% - 27px);
  bottom: -10%;
  & svg {
    @media (min-width: 992px) {
      width: 29px;
      height: 29px;
    }

    @media (max-width: 991.98px) {
      width: 21px;
      height: 21px;
    }
  }

  @media (max-width: 991px) {
    bottom: -20%;
  }
`

const BannerLanding: React.FC<BannerProps> = ({ fields }) => {
  const bounceTransition = {
    y: {
      yoyo: Infinity,
    },
  }

  return (
    <section>
      <BannerWrapper className="position-relative">
        <BannerIframeWrapper
          className="position-relative overflow-hidden"
          style={{ top: 0 }}
        >
          <Iframe
            title="Kokall header"
            src={`${fields.video}&muted=1&loop=1&autoplay=1&background=1`}
            width="100%"
            height="100%"
            style={{ border: 0 }}
            loading="lazy"
            allow="autoplay"
            className="position-absolute"
          />
        </BannerIframeWrapper>
        <ContentWrapper className="position-absolute w-100">
          <div className="container h-100 py-lg-5">
            <div className="row h-100 align-items-center">
              <Content content={fields.description || ''} />
              <ChevronWrapper
                className="position-absolute"
                animate={{ y: [10, 0] }}
                transition={bounceTransition}
              >
                <NavLink to="/#producten">
                  <Chevron />
                </NavLink>
              </ChevronWrapper>
            </div>
          </div>
        </ContentWrapper>
      </BannerWrapper>
    </section>
  )
}

export default BannerLanding
